//
// Overflow
//

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-auto {
  overflow: auto !important;
}
